import React, { FC, useState } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BrandFilter } from '@marriott/mi-shop-components/src/molecules';
import { PropertyFacet, PropertySearchTermFacetBucket } from '@marriott/mi-groups-graphql';
import { CheckboxControlsList, RadiobuttonControlsList, baseVariables } from '@marriott/mi-ui-library';
import { facetsBaseConfig } from '../FilterBar/FilterBar';
import { AllFiltersModalProps } from './AllFiltersModal.types';
import { StyledAllFiltersModal } from './AllFiltersModal.styles';
import { FilterModal } from '../../../molecules/FilterModal';
import { getAmenitiesCheckboxControlsList } from '../AmenitiesFilterModal/AmenitiesFilterModal';
import { getAllBrands, getSelectableBrands } from '../BrandFilterModal/BrandFilterModal';
import { CheckboxControlsListControlProp } from '../AmenitiesFilterModal/AmenitiesFilterModal.types';
import { BrandItem, SearchType, SelectedFacets } from '../SearchResults.types';
import { useMediaQuery } from '../../../hooks';
import { MILES_TO_METERS } from '../../../constants';
import { useSearchResultsStore } from '../../../store';

const formatDistanceControls = (distances: PropertySearchTermFacetBucket[], distanceMeasurementUnit: string) => {
  return distances.map(distance => {
    if (distance.code) {
      return distance;
    }

    const label = `${Math.floor(Number(distance.start) / MILES_TO_METERS)} - ${Math.floor(
      Number(distance.end) / MILES_TO_METERS
    )} ${distanceMeasurementUnit}`;

    return {
      code: distance.index?.toString(),
      count: distance.count?.toString(),
      label,
      disabled: !distance.count,
    };
  });
};

const getDistanceRadioControlsList = (
  defaultSelectedDistance: { label: string },
  distances: PropertySearchTermFacetBucket[],
  distanceMeasurementUnit: string
) => {
  const distanceControls = formatDistanceControls(distances, distanceMeasurementUnit);
  return [defaultSelectedDistance, ...distanceControls];
};

export const AllFiltersModal: FC<AllFiltersModalProps> = props => {
  const {
    filterType,
    labels,
    facetNames,
    facets,
    selectedFacets,
    brandCategories,
    showModal,
    isLoading,
    onFilterChange,
    onApply,
    onClear,
    onCloseModal,
  } = props;

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const [facetsSelected, setFacetsSelected] = useState<SelectedFacets>(selectedFacets);
  const searchType = useSearchResultsStore(state => state.searchType);

  const modifiedBrandCategories = getAllBrands(brandCategories, facets.BRANDS);

  const amenitiesCheckboxControlsList = getAmenitiesCheckboxControlsList(facets.AMENITIES ?? []);

  const defaultSelectedDistance = { label: `${labels.anyDistanceLabel}` };
  const distanceRadioControlsList = getDistanceRadioControlsList(
    defaultSelectedDistance,
    facets.DISTANCE ?? [],
    labels.distanceMeasurementUnit
  );

  //handlers
  const handleChange = (facetType: PropertyFacet, selectedValues: PropertySearchTermFacetBucket[] | BrandItem[]) => {
    const isAnyDistance = (selectedValues as PropertySearchTermFacetBucket[]).some(
      distance => distance.label === labels.anyDistanceLabel
    );
    if (facetType === PropertyFacet.DISTANCE && isAnyDistance) {
      setFacetsSelected(prev => ({ ...prev, [facetType]: [] }));
    } else {
      setFacetsSelected(prev => ({ ...prev, [facetType]: selectedValues }));
    }
    onFilterChange(facetType, selectedValues);
  };

  const handleClear = () => {
    setFacetsSelected(facetsBaseConfig);
    onClear(filterType);
  };

  // function to get checkboxlist based on filter props
  const getCheckboxControlsList = (
    controls = [] as CheckboxControlsListControlProp[],
    defaultSelected: CheckboxControlsListControlProp[],
    onChange: (selectedValues: PropertySearchTermFacetBucket[]) => void,
    filterName?: string,
    isLastCategory?: boolean,
    customProps = {}
  ) => {
    return controls.length ? (
      <CheckboxControlsList
        key={filterName}
        title={filterName}
        name={filterName as string}
        controls={controls}
        defaultSelected={defaultSelected}
        onChange={selectedValues => onChange(selectedValues as PropertySearchTermFacetBucket[])}
        showSeparator={!isLastCategory}
        showMoreLabel={labels.showMoreLabel}
        showLessLabel={labels.showLessLabel}
        {...customProps}
      />
    ) : null;
  };

  // brand category
  const brandFilters = (
    <BrandFilter
      key={facetNames.BRANDS}
      title={facetNames.BRANDS as string}
      brandCategories={modifiedBrandCategories}
      defaultSelected={facetsSelected.BRANDS ?? []}
      isHotelBrandSelectedFlag={true}
      onChange={selectedBrandItems => handleChange(PropertyFacet.BRANDS, getSelectableBrands(selectedBrandItems))}
      showSeparator={true}
    />
  );

  // distance category
  const distanceFilters = (
    <RadiobuttonControlsList
      key={facetNames.DISTANCE}
      name={facetNames.DISTANCE as string}
      title={facetNames.DISTANCE}
      controls={distanceRadioControlsList}
      defaultSelected={
        facetsSelected.DISTANCE?.length
          ? formatDistanceControls(facetsSelected.DISTANCE, labels.distanceMeasurementUnit)
          : [defaultSelectedDistance]
      }
      showSeparator={true}
      tabIndexForInput={-1}
      onChange={selectedValues =>
        handleChange(PropertyFacet.DISTANCE, selectedValues as PropertySearchTermFacetBucket[])
      }
    />
  );

  // used getCheckboxControlsList function to get other category filters
  const amenitiesFilter = getCheckboxControlsList(
    amenitiesCheckboxControlsList,
    facetsSelected.AMENITIES ?? [],
    selectedValues => handleChange(PropertyFacet.AMENITIES, selectedValues),
    facetNames.AMENITIES,
    false,
    { colLength: isTabletAndAbove ? 6 : 12, enableShowMoreToggle: true, showMoreCount: 8 }
  );

  const transportationFilter = getCheckboxControlsList(
    facets.TRANSPORTATION_TYPES,
    facetsSelected.TRANSPORTATION_TYPES ?? [],
    selectedValues => handleChange(PropertyFacet.TRANSPORTATION_TYPES, selectedValues),
    facetNames.TRANSPORTATION_TYPES
  );

  const eventsFilter = getCheckboxControlsList(
    facets.MEETINGS_EVENTS,
    facetsSelected.MEETINGS_EVENTS ?? [],
    selectedValues => handleChange(PropertyFacet.MEETINGS_EVENTS, selectedValues),
    facetNames.MEETINGS_EVENTS,
    false,
    { colLength: isTabletAndAbove ? 6 : 12 }
  );

  const statesFilter =
    searchType === SearchType.LOCATION_STATE ? (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <React.Fragment></React.Fragment>
    ) : (
      getCheckboxControlsList(
        facets.STATES,
        facetsSelected.STATES ?? [],
        selectedValues => handleChange(PropertyFacet.STATES, selectedValues),
        facetNames.STATES
      )
    );

  const citiesFilter = getCheckboxControlsList(
    facets.CITIES,
    facetsSelected.CITIES ?? [],
    selectedValues => handleChange(PropertyFacet.CITIES, selectedValues),
    facetNames.CITIES
  );

  const activitiesFilter = getCheckboxControlsList(
    facets.ACTIVITIES,
    facetsSelected.ACTIVITIES ?? [],
    selectedValues => handleChange(PropertyFacet.ACTIVITIES, selectedValues),
    facetNames.ACTIVITIES,
    true
  );

  // all filters array
  const allfilters = [
    brandFilters,
    distanceFilters,
    amenitiesFilter,
    transportationFilter,
    eventsFilter,
    statesFilter,
    citiesFilter,
    activitiesFilter,
  ];

  return (
    <StyledAllFiltersModal>
      <FilterModal
        labels={labels}
        show={showModal}
        bodyCustomClass={'custom-scrollbar'}
        children={allfilters as JSX.Element[]}
        isLoading={isLoading}
        onCloseModal={onCloseModal}
        onClearSelectedFilters={handleClear}
        onApplySelectedFilters={() => onApply(filterType, facetsSelected, true)}
      />
    </StyledAllFiltersModal>
  );
};
