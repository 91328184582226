import { useEffect } from 'react';
import { TAB_KEY } from '../constants';

export function useFocusOut({
  ref,
  onFocusOut,
  handleClick = true,
  handleKeyup = false,
}: {
  ref: HTMLElement | null;
  onFocusOut: (isFocusOut: boolean) => void;
  handleClick?: boolean;
  handleKeyup?: boolean;
}) {
  useEffect(() => {
    function clickListener(event: MouseEvent) {
      if (ref && handleClick) {
        if (ref.contains(event.target as Node)) {
          onFocusOut(false);
        } else {
          onFocusOut(true);
        }
      }
    }

    function keyupListener(event: KeyboardEvent) {
      if (ref && handleKeyup && event.key === TAB_KEY) {
        if (ref.contains(event.target as Node)) {
          onFocusOut(false);
        } else {
          onFocusOut(true);
        }
      }
    }

    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', keyupListener);

    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', keyupListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}
