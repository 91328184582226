import React, { FC, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useLazyQuery } from '@apollo/client';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  Modal,
  Button,
  Accordion,
  Text,
  Types,
  Link,
  toRem,
  baseVariables,
  MapComponent,
  MapMarker,
  InlineMessages,
  InlineMessagesType,
} from '@marriott/mi-ui-library';
import { searchPropertyQuery, PropertyDetails, HotelMediaContent } from '@marriott/mi-groups-graphql';
import {
  getPropertyItemValue,
  getPropertyAirportDistanceLabel,
  getEquipmentAndServices,
  getAddress,
  getReviewsUrl,
  isRfpEligible,
  isFutureDate,
} from '../../utils';
import { useLocaleStore } from '../../store';
import { CHINESE_LANG, GOOGLE_MAP_SEARCH_URL, PROPERTY_IMAGE_LIST_CATEGORY_LIMIT } from '../../constants';
import { useMediaQuery } from '../../hooks';
import { ImageCarousel } from '../../molecules';
import { PropertyQuickViewModalProps } from './PropertyQuickViewModal.types';
import { StyledPropertyQuickViewModal } from './PropertyQuickViewModal.styles';
import { CapacityChart } from './CapacityChart';

export const PropertyQuickViewModal: FC<PropertyQuickViewModalProps> = ({
  labels,
  show,
  propertyId,
  footerClass = '',
  error,
  visibilityConfig = {
    capacity: true,
    equipmentAndServices: true,
    footer: true,
  },
  onCloseModal,
  onAddToRfp,
}) => {
  const { color } = baseVariables;

  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);
  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);

  const [getPropertyDetails, { data: propertyDetails, loading, error: searchPropertyQueryError }] = useLazyQuery<{
    property: PropertyDetails;
  }>(searchPropertyQuery);

  const { locale } = useLocaleStore();

  const fetchPropertyDetails = () => {
    if (propertyId) {
      getPropertyDetails({
        variables: { propertyId, imageLimitPerCategory: PROPERTY_IMAGE_LIST_CATEGORY_LIMIT },
        context: {
          headers: {
            'accept-language': locale,
          },
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  useEffect(fetchPropertyDetails, [getPropertyDetails, locale, propertyId]);

  const { property } = propertyDetails || {};
  const {
    basicInformation,
    matchingSearchFacets,
    airports,
    contactInformation,
    meetingServices,
    reviews,
    seoNickname,
    facilitiesAndServices,
    media,
    meetingRooms,
  } = property || { seoNickname: '', airports: [] };
  const { name, descriptions, latitude, longitude, brand, openingDate } = basicInformation || {};
  const { address } = contactInformation || {};

  const propertyAddress = getAddress(
    address?.line1 ?? '',
    address?.city ?? '',
    address?.stateProvince?.description ?? '',
    address?.country?.description ?? '',
    address?.postalCode ?? ''
  );

  const airportDistanceLabel = getPropertyAirportDistanceLabel(airports, labels.toAirport, true);
  const reviewLabel = reviews?.numberOfReviews?.count
    ? `(${getPropertyItemValue(reviews.numberOfReviews.count)} ${labels.reviews})`
    : '';
  const reviewUrl = getReviewsUrl(seoNickname, brand?.id);

  const showMedia = useMemo(
    () =>
      media && (media.primaryImage || (media.photoGallery && Object.values(media.photoGallery).some(value => value))),
    [media]
  );
  const isOpeningSoon = !!(openingDate && isFutureDate(openingDate));

  const overviewLabel = descriptions?.find(item => item.type.code === 'location')?.localizedText?.translatedText;

  const equipmentAndServices = labels?.equipmentAndServices
    ? getEquipmentAndServices(meetingServices?.edges ?? [], facilitiesAndServices ?? [], labels.equipmentAndServices)
    : {};

  const apiKey = process.env['GOOGLE_MAP_API_KEY'] ?? '';
  const mapId = process.env['GOOGLE_MAP_ID'] ?? '';

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    mapIds: [mapId],
  });

  const mapOptions = {
    fullscreenControl: true,
    zoomControl: true,
    gestureHandling: 'greedy',
    mapTypeControlOptions: {
      mapTypeIds: [window.google.maps.MapTypeId.ROADMAP, window.google.maps.MapTypeId.SATELLITE],
      style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
    },
  };

  return (
    <StyledPropertyQuickViewModal
      data-component-name="m-groups-PropertyQuickViewModal"
      data-testid="groups-PropertyQuickViewModal"
    >
      <Modal
        role="dialog"
        show={show}
        handleBlur={true}
        popupOpenState={show}
        setPopupOpenState={onCloseModal}
        onClose={onCloseModal}
        labelledBy="propertyQuickViewModal"
        modalId="propertyQuickViewModal"
        disableScrollOnBody={true}
      >
        <Modal.Header
          headingTagElement={Types.tags.div}
          labelText={labels.hotelDetails}
          popupHeaderOnCLoseFunc={onCloseModal}
          className="modal-header"
        ></Modal.Header>

        <Modal.Body className="modal-body custom-scrollbar">
          {!loading && !searchPropertyQueryError ? (
            <>
              <div className="mb-3">
                <div className={clsx('m-0', isDesktop ? 't-subtitle-xl' : isTablet ? 't-subtitle-l' : 't-subtitle-m')}>
                  {name}
                </div>

                {address && (
                  <div className="address">
                    <span className={clsx(isDesktop ? 't-font-m' : 't-font-s')}>{propertyAddress}</span>
                  </div>
                )}

                <div className="d-flex my-md-4 my-3">
                  {reviewLabel && (
                    <Link linkClassName="d-flex mr-3 reviews-container" linkHref={reviewUrl}>
                      <div className="m-ratings-md align-items-center">
                        <div className="m-ratings-stars mr-1">
                          <span className="icon-star-fill" />
                        </div>
                        <span className={clsx('mr-2', isTablet ? 't-font-m' : 't-font-xs')}>
                          {reviews?.stars?.count}
                        </span>
                        <span className={clsx(isTablet ? 't-font-m' : 't-font-xs')}>{reviewLabel}</span>
                      </div>
                    </Link>
                  )}
                  {airportDistanceLabel && (
                    <div className="m-icon-text ">
                      {reviewLabel && <span className="mr-3">|</span>}
                      <span className="icon-s icon-location"></span>
                      <span className={clsx('pr-3', isTablet ? 't-font-m' : 't-font-xs')}>{airportDistanceLabel}</span>
                    </div>
                  )}
                </div>
              </div>

              {showMedia && (
                <ImageCarousel
                  media={media as HotelMediaContent}
                  labels={{
                    carouselAriaLabel: labels.carouselAriaLabel?.replace('{name}', name as string),
                    imageAriaLabel: labels.carouselImageAriaLabel,
                  }}
                  isCarouselIndicatorCentered
                  hasDescription
                  imageLoading="eager"
                />
              )}

              <div>
                {overviewLabel && (
                  <Accordion
                    id="overview"
                    isOpen={true}
                    customClass="border-top py-2"
                    headerChildren={
                      <Text copyText={labels.overview} fontSize={Types.size.medium} element={Types.tags.span} />
                    }
                  >
                    <div>
                      <Text copyText={overviewLabel} fontSize={Types.size.small} element={Types.tags.paragraph} />
                    </div>
                  </Accordion>
                )}

                {matchingSearchFacets && (
                  <Accordion
                    id="amenities"
                    isOpen={true}
                    customClass="border-top py-2"
                    headerChildren={
                      <Text copyText={labels.amenities} fontSize={Types.size.medium} element={Types.tags.span} />
                    }
                  >
                    <div className="d-flex flex-wrap amenities-container">
                      {matchingSearchFacets.map((item, index) => {
                        const icon =
                          item.dimension?.code && !item.dimension.code.includes(' ')
                            ? `icon-${item.dimension.code}`
                            : '';
                        return (
                          <div key={index} className="mb-4 m-icon-text amenity">
                            <span className={clsx('icon-m icon-check', icon)}></span>
                            <span className="t-font-s">{item.dimension.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </Accordion>
                )}

                {visibilityConfig.capacity && labels.capacity && meetingRooms?.edges?.length ? (
                  <Accordion
                    id="capacity"
                    isOpen={true}
                    customClass="border-top py-2"
                    headerChildren={
                      <Text copyText={labels.capacity.title} fontSize={Types.size.medium} element={Types.tags.span} />
                    }
                  >
                    <CapacityChart labels={labels.capacity} values={meetingRooms.edges} />
                  </Accordion>
                ) : null}

                {visibilityConfig.equipmentAndServices && labels.equipmentAndServices && equipmentAndServices && (
                  <Accordion
                    id="equipmentAndServices"
                    isOpen={true}
                    customClass="border-top py-2"
                    headerChildren={
                      <Text
                        copyText={labels.equipmentAndServices.title}
                        fontSize={Types.size.medium}
                        element={Types.tags.span}
                      />
                    }
                  >
                    <div className="accordion-container equipment-service-container">
                      {Object.entries(equipmentAndServices).map(([category, categoryItems]) => (
                        <div key={category} className="mb-4 category">
                          <span className="my-2 category-title">{category}</span>
                          <div className="category-value">
                            {categoryItems.map((value, index) => (
                              <span className="t-font-s my-2" key={index}>
                                {value}
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion>
                )}
              </div>

              {locale !== CHINESE_LANG && (
                <div role="region" aria-label={labels.mapAriaLabel} tabIndex={0}>
                  {isLoaded && (
                    <div className="map-container">
                      <MapComponent
                        googleMapsApiKey={apiKey}
                        mapId={mapId}
                        mapContainerStyle={{ height: '100%', width: '100%' }}
                        mapHeight={isTablet ? toRem(420) : toRem(270)}
                        zoom={15}
                        center={{ lat: latitude || 0, lng: longitude || 0 }}
                        options={mapOptions}
                        children={
                          <MapMarker
                            label={{
                              text: name as string,
                              fontSize: toRem(14),
                              color: color.base20,
                              className: `m-map-pin map-marker`,
                            }}
                            position={{ lat: latitude || 0, lng: longitude || 0 }}
                            clickable={true}
                            onClick={() => {
                              window.open(`${GOOGLE_MAP_SEARCH_URL}?api=1&query=${name}`, '_blank');
                            }}
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : searchPropertyQueryError ? (
            <InlineMessages type={InlineMessagesType.Error} title={error} severity="1" />
          ) : (
            <div className="m-spinner-m d-block mx-auto my-0"></div>
          )}
        </Modal.Body>

        {visibilityConfig.footer && isRfpEligible(brand as { id: string }, isOpeningSoon, openingDate as string) ? (
          <Modal.FooterGeneric
            className="footer-section"
            actions={
              !loading && !searchPropertyQueryError ? (
                <Button
                  ariaLabel={labels.addToRfpAriaLabel}
                  className={clsx('m-button-m m-button-primary', footerClass ?? '')}
                  callback={onAddToRfp}
                >
                  {labels.addToRfp}
                </Button>
              ) : null
            }
          ></Modal.FooterGeneric>
        ) : null}
      </Modal>
    </StyledPropertyQuickViewModal>
  );
};
