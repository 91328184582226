import { Amount, Brand, HotelMediaContent } from '@marriott/mi-groups-graphql';

export type PropertyCardProps = {
  id: string | number;
  type?: PropertyCardType;
  media: HotelMediaContent;
  badge?: string;
  brand: Brand;
  mediaLabels?: {
    carouselAriaLabel: string;
    carouselImageAriaLabel: string;
    logoAriaLabel: string;
  };
  propertyName: PropertyName;
  reviewsAndDistance: ReviewsAndDistance;
  showDescription?: boolean;
  description: { label: string; value: string }[] | string;
  viewDetailsCta?: Cta;
  price?: {
    amount: number;
    currencyLabel: string;
  };
  footer?: {
    primaryCta?: Cta;
    secondaryCta?: Cta;
    pricingInfo?: PricingInfo;
  };
};

export enum PropertyCardType {
  REGULAR,
  COMPACT,
}

export type PropertyName = { title: string; onClick?: (e: unknown) => void };

export type ReviewsAndDistance = {
  rating: string;
  reviewCount: string;
  reviewLink: string;
  distance: string;
};

export type Cta = {
  label: string;
  isLink?: boolean;
  linkUrl?: string;
  isSelected?: boolean;
  trackLocation?: string;
  trackDescription?: string;
  onClick?: (e: unknown) => void;
};

export type PricingInfo = {
  labels: PricingInfoLabels;
  data: PricingInfoData;
};

export type PricingInfoLabels = {
  guestRoomsFrom: string;
  perNight: string;
  eventSpacesFrom: string;
  perDay: string;
};

type PricingInfoData = {
  roomRate: Amount;
  spaceRate: Amount;
};
