import styled from 'styled-components';
import { StyledTypeAheadFlyout } from './TypeAheadFlyout/TypeAheadFlyout.styles';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, mediaQuery, font } = baseVariables;

export const StyledDestination = styled.div`
  height: 100%;
  .show-flyout-scrollable {
    height: 100%;
  }
  @media ${mediaQuery.md} {
    .show-flyout-scrollable {
      height: fit-content;
      position: absolute;
      width: 100%;
      z-index: 100;
      box-sizing: border-box;
      border: ${toRem(1)} solid ${color.neutral30};
      border-radius: 0 0 ${toRem(14)} ${toRem(14)};
      background-color: ${color.merch50};
      box-shadow: 0 ${toRem(2)} ${toRem(8)} 0 rgba(0, 0, 0, 0.07); //color should be mi-ui-library
    }

    ${StyledTypeAheadFlyout} {
      .type-ahead-flyout {
        overflow: hidden;
        padding-left: 0 !important;
      }

      &:not(.type-ahead-flyout) {
        .MuiAutocomplete-paper {
          display: none;
        }
      }

      &.search-page-destination {
        width: 100%;
        padding: 0;
        .MuiInputBase-formControl {
          .MuiAutocomplete-endAdornment {
            color: ${color.base10};
            right: ${toRem(6)};
            .icon-cancel {
              color: ${color.base10};
            }
          }
        }
      }
    }
    position: relative;
  }
  .MuiFormHelperText-root {
    &.Mui-error {
      font-weight: ${font.fontWeightRegular};
    }
  }
`;
