import { FC, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { baseVariables, Button, DropDownModal, getCurrentDateObject, getNextDateObject } from '@marriott/mi-ui-library';
import { CALENDAR_VARIATION_DESKTOP, CALENDAR_END_DATE, CALENDAR_START_DATE, ENTER_KEY } from '../../../constants';
import { StyledDatePickerDesktop } from './DatePickerDesktop.styles';
import { useMediaQuery } from '../../../hooks';
import { getDatesLabel, getFormattedDates, getFormattedDate, setCalendarOptions } from '../../../utils';
import { DatePickerDesktopProps, DatePickerDesktopState } from './DatePickerDesktop.types';
import { DateObject, SelectedDateType } from '../DatePicker.types';
import { TextFormField } from '../../TextFormField';
import { DatePicker } from '../DatePicker';
import { CheckBoxFormField } from '../../CheckBoxFormField';

export const DatePickerDesktop: FC<DatePickerDesktopProps> = ({
  dates,
  align = 'right',
  iconAriaLabel,
  selectedStartDate,
  selectedEndDate,
  isDateRangeFixed = false,
  rangeStartDate,
  rangeEndDate,
  setDefaultDates = true,
  showFlexibleDates = true,
  flexibleDates: flexibleDatesValue = false,
  showErrorMessage = false,
  errorMessage,
  clickTrackingLoc,
  isPastDateSelectionDisabled,
  enableFromPastDate,
  clearDates,
  onChange,
  onClose,
}) => {
  const {
    label,
    placeholder,
    startDate: startDateLabel,
    startDatePlaceholder,
    endDate: endDateLabel,
    endDatePlaceholder,
    night,
    nights,
    flexibleDates: flexibleDatesLabel,
    done,
    resetFields,
    shortWeekdayInitials,
    shortWeekdayNames,
    longWeekdayNames,
    shortMonthNames,
    longMonthNames,
  } = dates;

  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);

  const [state, setState] = useState<DatePickerDesktopState>({
    startDate: null,
    endDate: null,
    isFlyoutOpen: false,
    selectedDateType: CALENDAR_START_DATE,
    resetDatePicker: false,
    flexibleDates: flexibleDatesValue,
  });

  const initialStartDate = useRef(getCurrentDateObject());
  const initialEndDate = useRef(getNextDateObject(initialStartDate.current));

  const datesLabel = getDatesLabel(state.startDate, state.endDate, label, night, nights);
  const dateInputValue = getFormattedDates(state.startDate, state.endDate);
  const formattedStartDate = getFormattedDate(state.startDate);
  const formattedEndDate = getFormattedDate(state.endDate);

  useEffect(() => {
    onChange?.(dateInputValue, formattedStartDate, formattedEndDate, state.flexibleDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInputValue, formattedEndDate, formattedStartDate, state.flexibleDates]);

  useEffect(() => {
    if (clearDates) {
      resetDates();
    }
  }, [clearDates]);

  useEffect(() => {
    setCalendarOptions({
      shortWeekdayNames,
      longWeekdayNames,
      shortMonthNames,
      longMonthNames,
    });
  }, [shortWeekdayNames, longWeekdayNames, shortMonthNames, longMonthNames]);

  const setDates = (
    selectedStartDate: DateObject | null,
    selectedEndDate: DateObject | null,
    selectedDateType: SelectedDateType,
    resetDatePicker = false
  ) => {
    setState(prevState => ({
      ...prevState,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      selectedDateType,
      resetDatePicker: resetDatePicker ?? prevState.resetDatePicker,
    }));
  };

  const setInitialDates = (
    startDate: DateObject,
    endDate: DateObject,
    defaultStartDate: DateObject,
    defaultEndDate: DateObject,
    selectedDateType: SelectedDateType
  ) => {
    initialStartDate.current = defaultStartDate;
    initialEndDate.current = defaultEndDate;
    setDates(startDate, endDate, selectedDateType);
  };

  const closeFlyout = useCallback(() => {
    setState(prevState => ({ ...prevState, isFlyoutOpen: false }));
    onClose?.();
  }, [onClose]);

  const toggleFlyout = () => setState(prevState => ({ ...prevState, isFlyoutOpen: !prevState.isFlyoutOpen }));

  const resetDates = () => setState(prevState => ({ ...prevState, resetDatePicker: true }));

  const { isFlyoutOpen, startDate, endDate, selectedDateType, resetDatePicker, flexibleDates } = state;

  const datesField = (
    <TextFormField
      label={state.startDate && state.endDate ? datesLabel : label}
      ariaLabel={dates['ariaLabel']}
      placeholder={placeholder}
      value={dateInputValue}
      className="m-input-field"
      hideCursor={true}
      showErrorMessage={showErrorMessage}
      errorMessage={errorMessage}
      testId="dates"
      onClick={toggleFlyout}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === ENTER_KEY) {
          event.preventDefault();
          toggleFlyout();
        }
      }}
    />
  );

  const startDateField = (
    <TextFormField
      label={startDateLabel}
      placeholder={startDatePlaceholder}
      value={getFormattedDate(state.startDate, 'dateWithDayAndMonth')}
      className={clsx('m-input-field', selectedDateType === CALENDAR_START_DATE && 'focus-input')}
      showIcon
      iconClass={clsx(
        'icon icon-cancel',
        startDate && !initialStartDate.current?.isSame(startDate, 'date') ? 'd-block' : 'd-none'
      )}
      iconAriaLabel={iconAriaLabel}
      iconClickHandler={resetDates}
      testId="startDate"
      getInputProps={() => ({
        readOnly: true,
      })}
    />
  );

  const endDateField = (
    <TextFormField
      label={endDateLabel}
      placeholder={endDatePlaceholder}
      value={getFormattedDate(state.endDate, 'dateWithDayAndMonth')}
      className={clsx('m-input-field', selectedDateType === CALENDAR_END_DATE && 'focus-input')}
      showIcon
      iconClass={clsx(
        'icon icon-cancel',
        endDate && !initialEndDate.current?.isSame(endDate, 'date') ? 'd-block' : 'd-none'
      )}
      iconAriaLabel={iconAriaLabel}
      iconClickHandler={resetDates}
      testId="endDate"
      getInputProps={() => ({
        readOnly: true,
      })}
    />
  );

  const calendar = (
    <DatePicker
      variation={CALENDAR_VARIATION_DESKTOP}
      nightLabel={night}
      nightsLabel={nights}
      shortWeekdayInitials={shortWeekdayInitials}
      shortWeekdayNames={shortWeekdayNames}
      longWeekdayNames={longWeekdayNames}
      shortMonthNames={shortMonthNames}
      longMonthNames={longMonthNames}
      setInitialDates={(startDate, endDate, initialStartDate, initialEndDate, selectedDateType) =>
        setInitialDates(startDate, endDate, initialStartDate, initialEndDate, selectedDateType)
      }
      setDates={(startDate, endDate, selectedDateType) => setDates(startDate, endDate, selectedDateType)}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      isDateRangeFixed={isDateRangeFixed}
      rangeStartDate={rangeStartDate}
      rangeEndDate={rangeEndDate}
      setDefaultDates={setDefaultDates}
      resetDatePicker={resetDatePicker}
      isDatePickerClosed={isFlyoutOpen}
      isPastDateSelectionDisabled={isPastDateSelectionDisabled}
      enableFromPastDate={enableFromPastDate}
    />
  );

  const flexibleDatesField = (
    <CheckBoxFormField
      options={[{ label: flexibleDatesLabel, value: 'yes' }]}
      colLength={12}
      labelClassName="flexible-dates-label"
      name="isFlexibleDate"
      onChange={value => setState(prevState => ({ ...prevState, flexibleDates: !!value }))}
      value={flexibleDates ? 'yes' : 'no'}
      testId="flexibleDates"
      className="flexible-dates"
    />
  );

  const resetCta = (
    <Button
      callback={resetDates}
      className={clsx('reset-cta', 'm-link-action', 'p-0', isDesktop ? 'text-right' : 'text-left')}
      custom_click_track_value={`${clickTrackingLoc}| ${resetFields} button |internal`}
      buttonCopy={resetFields}
      testId="reset"
    />
  );

  const doneCta = (
    <Button
      callback={closeFlyout}
      className="m-button-s m-button-primary w-100"
      custom_click_track_value={`${clickTrackingLoc}| ${done} button |internal`}
      buttonCopy={done}
      testId="done"
    />
  );

  return (
    <StyledDatePickerDesktop align={align}>
      <section>{datesField}</section>
      <section className="date-picker-modal">
        <DropDownModal
          className="groups-datepicker-modal"
          show={isFlyoutOpen}
          handleBlur={true}
          dropdownModalOpenState={isFlyoutOpen}
          dropdownModalOnCLoseFunc={closeFlyout}
          setDropdownModalOpenState={closeFlyout}
        >
          <section className="row">
            <div className={clsx(isDesktop ? 'col-md-6' : 'col-md-12 mt-2', 'pt-2')}>{startDateField}</div>
            <div className={clsx(isDesktop ? 'col-md-6' : 'col-md-12 mt-3', 'pt-2')}>{endDateField}</div>
          </section>
          {calendar}
          <section className="datepicker-footer row mt-2 align-items-center mx-2 pt-3">
            {showFlexibleDates && <section className="col-sm-12 col-lg-6 mt-2 pl-0">{flexibleDatesField}</section>}
            <section
              className={clsx(
                'col-sm-12',
                'col-lg-5',
                'px-0',
                showFlexibleDates ? 'offset-lg-1 pt-2' : 'col-sm-12 col-lg-5 offset-lg-7 mt-2 '
              )}
            >
              <div className="row align-items-center">
                <div className="col-7 my-1 pl-0">{resetCta}</div>
                <div className="col-5 my-1 p-0">{doneCta}</div>
              </div>
            </section>
          </section>
        </DropDownModal>
      </section>
    </StyledDatePickerDesktop>
  );
};
