import axios from 'axios';
import {
  GroupRate,
  PropertyFacet,
  PropertySearchFacetInput,
  PropertySearchSortField,
  PropertySearchSortFieldDirection,
  PropertySearchSortInput,
  Property,
  SuggestedPlaceDetails,
  SuggestionDataTypesForInput,
  GroupRatesPropertySearchQueryInput,
  PropertySearchQueryInput,
} from '@marriott/mi-groups-graphql';
import { RecentSearchItem } from '../organisms/GroupSearchForm/Destination/DefaultFlyout/DefaultFlyout.types';
import {
  RECENT_SEARCH_CACHE_KEY,
  RECENT_LIST_LENGTH,
  SEARCH_CRITERIA,
  QUERY_PARAMS_TO_FACET_KEYS,
  SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS,
  SEARCH_RESULTS_PAGE_SIZE,
  QUERY_PARAM_DESTINATION,
  QUERY_PARAM_ARIES_SEARCH_VO_DESTINATION,
  QUERY_PARAM_ARIES_SEARCH_DA_DESTINATION,
  QUERY_PARAMS_ARIES_SEARCH_VO_MAP,
  QUERY_PARAMS_ARIES_SEARCH_DA_MAP,
  ARIES_SEARCH_VO_FACET_KEYS_MAP,
  ARIES_SEARCH_DA_FACET_KEYS_MAP,
  QG_DATA_CACHE_KEY,
  OTHER_EVENT_TYPE,
} from '../constants';
import { CacheManager } from './cacheManager';
import {
  AriesSearchDAQueryParams,
  AriesSearchVOQueryParams,
  EventType,
  GroupSearchFormData,
  SearchCriteria,
  SearchQueryData,
  ValueType,
} from '../organisms/GroupSearchForm/GroupSearchForm.types';
import {
  getFormattedCalendarDate,
  getDatesInRange,
  getFormattedDate,
  getFormattedDateObject,
  getFormattedDateString,
  getDurationInDays,
} from './date';
import { ParsedUrlQuery } from 'querystring';
import { getCurrentDateObject, getNextDateObject } from '@marriott/mi-ui-library';
import { getQueryParams } from './searchResults';
import { QuickGroupData, SearchType } from '../organisms/SearchResults/SearchResults.types';
import { updateSession } from './session';

export type SearchQueryOptions = {
  latitude?: string;
  longitude?: string;
  guestRooms?: string;
  attendees?: string;
  destination?: string;
  startDate?: string;
  endDate?: string;
  flexibleDates?: string;
  eventType?: string;
} & SearchQueryAdditionalOptions;

type SearchQueryAdditionalOptions = {
  facets?: PropertySearchFacetInput;
  city?: string;
  states?: string;
  countries?: string;
  sort?: PropertySearchSortInput;
  offset?: number;
};

export type BookNowPayload = {
  searchType: string;
  siteId: string;
  dateFormatPattern: string;
  hwsSearchSgo: string;
  isHwsQGForm: string;
  'destinationAddress.city': string;
  'destinationAddress.stateCode': string;
  'destinationAddress.stateProvince': string;
  'destinationAddress.country': string;
  hwsMarshaCode: string;
  hwsPropertyName: string;
  marshaCode: string;
  roomsOnlySelected: boolean;
  roomsAndEventSelected: boolean;
  roomOnlySelectedAries?: boolean;
  roomAndEventSelectedAries?: boolean;
  roomCount: string;
  largestMeetingSpaceOrAttendees: string;
  eventType: string;
  populateTodateFromFromDate: string;
  sgoSearch: string;
  sgoSupported: string;
  defaultToDateDays: string;
  eventOptionsRadio: string;
  formType: string;
  meetingSpaceUnits: string;
  fromDate: string;
  toDate: string;
  monthNames: string;
  weekDays: string;
  brandCode: string;
  functionSpaceID: string;
  groupId: string;
  marshaBrandCode: string;
  functionSpaceName: string;
  eventSpaceSelection: Record<string, string>[];
};

export const recentSearchesStorage = new CacheManager<RecentSearchItem[]>(RECENT_SEARCH_CACHE_KEY);

export const getPlaceDescription = (description: string) => {
  try {
    const parsedDescription = JSON.parse(description);
    if (typeof parsedDescription === 'object') {
      return parsedDescription;
    }
    throw new Error();
  } catch {
    return {
      description,
    };
  }
};

const getRecentSearchItem = (
  placeDetails: SuggestedPlaceDetails,
  placeDescription: SuggestionDataTypesForInput
): RecentSearchItem => ({
  label: placeDescription?.description ? `${placeDescription?.description}` : '',
  city: placeDetails?.location?.city ? `${placeDetails?.location?.city}` : '',
  poiname: '',
  states: placeDetails?.location?.state ? `${placeDetails?.location?.state}` : '',
  countries: placeDetails?.location?.country ? `${placeDetails?.location?.country}` : '',
  stateprovincedisplayname: '',
  type: '',
  countryname: placeDetails?.location?.countryName ? `${placeDetails?.location?.countryName}` : '',
  airportcode: '',
  value: placeDescription?.description ? `${placeDescription?.description}` : '',
  geocode:
    placeDetails?.location?.latitude && placeDetails?.location?.longitude
      ? `${placeDetails?.location?.latitude},${placeDetails?.location?.longitude}`
      : '',
  searchtype: 'recent',
  analytics: '{"location":"groupsSearchForm","sendNow":"true","description":"Recent Search"}',
  destination: placeDescription?.description ? `${placeDescription?.description}` : '',
  placeId: placeDetails?.placeId ? `${placeDetails?.placeId}` : '',
  primaryDescription: placeDescription?.primaryDescription ? `${placeDescription?.primaryDescription}` : '',
  secondaryDescription: placeDescription?.secondaryDescription ? `${placeDescription?.secondaryDescription}` : '',
  latitude: placeDetails?.location?.latitude ? `${placeDetails?.location?.latitude}` : '',
  longitude: placeDetails?.location?.longitude ? `${placeDetails?.location?.longitude}` : '',
  radius: '',
  address: '',
  postalCode: '',
  types: '',
  website: '',
});

// returns the data coming from search form submissions from within the Phoenix system
export const getBasicSearchCriteria = (queryData: Partial<SearchQueryData>): Partial<SearchQueryData> => {
  const result: Record<string, string> = {};
  SEARCH_CRITERIA.forEach(paramName => {
    if (paramName in queryData && queryData[paramName as keyof SearchQueryData]) {
      result[paramName] = queryData[paramName as keyof SearchQueryData] || '';
    }
  });
  // If 'latitude' and 'longitude' are present in 'result', it indicates a geolocation search.
  // The 'states' and 'countries' property is removed from the 'result' object, to avoid parsing it as applied filters on searchResults
  // If 'states' is present along with 'countries', it indicates a state location-based search.
  // The 'countries' property is removed from the 'result' object.
  // This is done because we are only interested in the state location for this search.
  if (result['latitude'] && result['longitude']) {
    delete result['states'];
    delete result['countries'];
  } else if (result['countries'] && result['states']) {
    delete result['countries'];
  }
  return result;
};

export const submitSearchForm = (
  queryData: SearchQueryData,
  isSearchResultPage: boolean,
  searchResultsUrl: string,
  callback: () => void
) => {
  const basicSearchCriteria = getBasicSearchCriteria(queryData);
  const queryParams = new URLSearchParams(basicSearchCriteria);
  if (!isSearchResultPage) {
    const serachResultsURL = `${searchResultsUrl}?${decodeURIComponent(queryParams.toString())}`;
    window.location.href = serachResultsURL;
  } else {
    window.history.pushState(null, '', decodeURIComponent(`?${queryParams.toString()}`));
    callback();
  }
};

// Methods to contruct queryparams from form data
const getFormattedSearchCriteriaParam = (key: string, value: ValueType): string => {
  if (key === 'startDate' || key === 'endDate') {
    return typeof value === 'string' ? getFormattedCalendarDate(value, 'hyphenatedDateWithMonthNoAndYear') : '';
  } else if (key === 'eventType') {
    return typeof value === 'object' ? value.code : '';
  } else {
    return value + '';
  }
};

const getFormattedSearchCriteria = (formData: GroupSearchFormData): SearchCriteria => {
  const searchCriteria: SearchCriteria = {};
  Object.entries(formData).forEach(([key, value]) => {
    if (!['dates', 'destination'].includes(key)) {
      searchCriteria[key as keyof SearchCriteria] = getFormattedSearchCriteriaParam(key, value as ValueType);
    }
  });
  return searchCriteria;
};

export const updateRecentSearches = (
  formdata: GroupSearchFormData,
  placeDetails?: SuggestedPlaceDetails
): SearchQueryData => {
  let recentSearch = {} as SearchQueryData;

  const updateRecentSearchesCallback = (recentSearchedItems: RecentSearchItem[]) => {
    const placeDescription = getPlaceDescription(JSON.stringify(formdata.destination));
    const recentSearches = [...recentSearchedItems];
    const searchedItemIndex = recentSearches.findIndex(item => item?.label === placeDescription?.description);
    const searchCriteria = getFormattedSearchCriteria(formdata);
    const recentSearchItem = getRecentSearchItem(placeDetails || ({} as SuggestedPlaceDetails), placeDescription);
    recentSearch = { ...recentSearchItem, ...searchCriteria };

    // if searched item is present, delete it and push it at last
    if (searchedItemIndex !== -1) {
      recentSearches.splice(searchedItemIndex, 1);
    } else if (recentSearchedItems.length >= RECENT_LIST_LENGTH) {
      // if 5 or more items are present, remove the first one
      recentSearches.shift();
    }
    recentSearches.push(recentSearch);
    return recentSearches;
  };

  recentSearchesStorage.updateItem(updateRecentSearchesCallback);
  return recentSearch;
};

export const updateAriesGroupSearchSession = async () => {
  const queryOption = getSearchQueryOptions(getQueryParams(window.location.search));
  const startDate = queryOption?.startDate;
  const endDate = queryOption?.endDate;
  const roomCount = +(queryOption?.guestRooms || 0);
  const attendeesCount = +(queryOption?.attendees || 0);

  const ariesGroupSearchSession = {
    AriesGroupSearch: {
      groupSearchCriteria: {
        address: {
          latitude: queryOption?.latitude,
          longitude: queryOption?.longitude,
          destination: queryOption?.destination,
          destinationAddressMainText: queryOption?.destination,
          city: queryOption?.city,
          stateProvince: queryOption?.states,
          country: queryOption?.countries,
        },
        checkInDate: `${startDate}T00:00:00.000+0000`,
        checkOutDate: `${endDate}T00:00:00.000+0000`,
        lengthOfStay: getDurationInDays(startDate, endDate),
        isFlexibleDate: queryOption?.flexibleDates,
        eventSearchType: queryOption?.eventType,
        guestRoomCount: roomCount,
        roomsOnlySelected: !!roomCount,
        sizeLargestMeetingRoom: attendeesCount,
        roomsAndEventSelected: !!attendeesCount,
      },
    },
  };

  await updateSession(ariesGroupSearchSession as unknown as Record<string, string>);
};

// Mapping Aries Query Params to Phoenix Groups Params, using the map object defined in constants.
const transformAriesSearchParams = (
  data: Partial<AriesSearchVOQueryParams | AriesSearchDAQueryParams>
): Partial<SearchQueryData> => {
  const result: Partial<SearchQueryData> = {};
  const searchCriteria = data[QUERY_PARAM_ARIES_SEARCH_VO_DESTINATION as keyof typeof data]
    ? QUERY_PARAMS_ARIES_SEARCH_VO_MAP
    : QUERY_PARAMS_ARIES_SEARCH_DA_MAP;

  for (const key in searchCriteria) {
    const ariesSearchKey = searchCriteria[key as keyof SearchCriteria] as keyof typeof data;
    if (data[ariesSearchKey]) {
      result[key as keyof SearchCriteria] = data[ariesSearchKey];
    }
  }

  const { startDate, endDate } = result;
  const currentStartDate = startDate
    ? getFormattedDateObject(startDate, 'dateWithMonthNoAndYear')
    : getCurrentDateObject();
  const formattedStartDate = getFormattedDate(currentStartDate, 'hyphenatedDateWithMonthNoAndYear');
  const formattedEndDate = endDate
    ? getFormattedDate(getFormattedDateObject(endDate, 'dateWithMonthNoAndYear'), 'hyphenatedDateWithMonthNoAndYear')
    : getFormattedDate(getNextDateObject(currentStartDate), 'hyphenatedDateWithMonthNoAndYear');

  return {
    ...result,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
};

const getSearchQueryData = (urlQuery: ParsedUrlQuery) => {
  if (urlQuery[QUERY_PARAM_DESTINATION]) {
    return urlQuery as Record<string, string>;
  } else if (urlQuery[QUERY_PARAM_ARIES_SEARCH_VO_DESTINATION] || urlQuery[QUERY_PARAM_ARIES_SEARCH_DA_DESTINATION]) {
    return transformAriesSearchParams(urlQuery);
  } else if (recentSearchesStorage.hasItem()) {
    return recentSearchesStorage.getLatestItem() as SearchQueryData;
  }
  return undefined;
};

export const getSearchCriteria = (eventTypeList: EventType) => {
  const defaultSearchCriteria = {
    destination: {
      placeId: '',
      description: '',
    },
    startDate: '',
    endDate: '',
    flexibleDates: false,
    eventType: { code: '', label: '' },
    guestRooms: '',
    attendees: '',
  };

  const data: Partial<SearchQueryData> | undefined = getSearchQueryData(getQueryParams(window.location.search));
  if (!data) return undefined;

  const { placeId, destination, flexibleDates } = data;
  const eventType = eventTypeList.options.find((option: { code: string }) => option.code === data.eventType) || {
    code: '',
    label: '',
  };
  return {
    ...defaultSearchCriteria,
    ...data,
    destination: { placeId, description: destination },
    flexibleDates: flexibleDates === 'true',
    eventType,
  };
};

export const getSearchQueryOptions = (
  searchParams: Record<string, string>,
  useAdditionalParams = true
): SearchQueryOptions => {
  const data: Partial<SearchQueryData> | undefined = getSearchQueryData(searchParams);
  if (!data) return {};

  const baseSearchOptions = (({
    destination,
    latitude,
    longitude,
    city,
    states,
    countries,
    startDate,
    endDate,
    flexibleDates,
    eventType,
    guestRooms,
    attendees,
  }) => ({
    destination,
    latitude,
    longitude,
    city,
    states,
    countries,
    startDate,
    endDate,
    flexibleDates,
    eventType,
    guestRooms,
    attendees,
  }))(data);

  if (baseSearchOptions['latitude'] && baseSearchOptions['longitude']) {
    delete searchParams['searchCriteriaVO.stateProvince'];
    delete searchParams['searchCriteriaVO.country'];
    delete searchParams['destinationAddress.stateProvince'];
    delete searchParams['destinationAddress.country'];
  } else if (baseSearchOptions['countries'] && baseSearchOptions['states']) {
    delete searchParams['searchCriteriaVO.country'];
    delete searchParams['destinationAddress.country'];
  }

  if (useAdditionalParams) {
    let queryParamsToFacetKeys: Record<string, PropertyFacet> = {};
    if (searchParams[QUERY_PARAM_DESTINATION]) {
      queryParamsToFacetKeys = QUERY_PARAMS_TO_FACET_KEYS;
    } else if (searchParams[QUERY_PARAM_ARIES_SEARCH_VO_DESTINATION]) {
      queryParamsToFacetKeys = ARIES_SEARCH_VO_FACET_KEYS_MAP;
    } else if (searchParams[QUERY_PARAM_ARIES_SEARCH_DA_DESTINATION]) {
      queryParamsToFacetKeys = ARIES_SEARCH_DA_FACET_KEYS_MAP;
    }

    const allFacets = Object.entries(queryParamsToFacetKeys)
      .filter(([paramName]) => searchParams[paramName])
      .map(([paramName, termFacetName]) => ({
        type: termFacetName,
        dimensions: [...new Set((searchParams[paramName] || '').split(','))],
      }));

    const termFacets = allFacets.filter(facet => facet.type !== PropertyFacet.DISTANCE);
    const [rangeFacet] = allFacets.filter(facet => facet.type === PropertyFacet.DISTANCE);

    return {
      ...baseSearchOptions,
      facets: {
        terms: termFacets,
        ...(rangeFacet
          ? {
              ranges: [
                {
                  dimensions: rangeFacet.dimensions,
                  endpoints: SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS,
                  type: rangeFacet.type,
                },
              ],
            }
          : {}),
      },
      sort: {
        fields: [
          ...(searchParams['sortBy'] && searchParams['sortBy'].trim() !== ''
            ? [
                {
                  field: searchParams['sortBy'].toUpperCase() as PropertySearchSortField,
                  direction: searchParams['sortOrder'].toUpperCase() as PropertySearchSortFieldDirection,
                },
              ]
            : [
                {
                  field: PropertySearchSortField.DISTANCE,
                  direction: PropertySearchSortFieldDirection.ASC,
                },
              ]),
        ],
      },
      ...(searchParams['page'] && parseInt(searchParams['page']).toString() === searchParams['page']
        ? {
            offset: (parseInt(searchParams['page']) - 1) * SEARCH_RESULTS_PAGE_SIZE,
          }
        : {}),
    };
  }

  return baseSearchOptions;
};

export const getBookNowPayload = (
  property: Property,
  groupRate: GroupRate | null,
  searchQuery: PropertySearchQueryInput | GroupRatesPropertySearchQueryInput,
  locale: string
) => {
  const queryOption = getSearchQueryOptions(getQueryParams(window.location.search));
  const lastSearchedData = recentSearchesStorage.getLatestItem();
  const roomCount = +(queryOption?.guestRooms || lastSearchedData?.guestRooms || 0);
  const attendeeCount = +(queryOption?.attendees || lastSearchedData?.attendees || 0);
  const startDate = queryOption?.startDate || lastSearchedData?.startDate;
  const endDate = queryOption?.endDate || lastSearchedData?.endDate;

  const bookNowPayload: BookNowPayload = {
    searchType: 'InCity',
    siteId: locale.split('_')?.[1]?.toUpperCase() || 'US',
    dateFormatPattern: 'MM/dd/yy',
    hwsSearchSgo: 'true',
    isHwsQGForm: 'true',
    'destinationAddress.city': lastSearchedData.city,
    'destinationAddress.stateCode': lastSearchedData.state,
    'destinationAddress.stateProvince': lastSearchedData.stateProvince,
    'destinationAddress.country': lastSearchedData.country,
    hwsMarshaCode: property?.id.toString(),
    hwsPropertyName: property?.basicInformation?.name || '',
    marshaCode: property?.id.toString(),
    roomsOnlySelected: !!roomCount,
    roomsAndEventSelected: !!attendeeCount,
    roomOnlySelectedAries: !!roomCount && !attendeeCount,
    roomAndEventSelectedAries: !!roomCount && !!attendeeCount,
    roomCount: roomCount ? roomCount.toString() : '',
    largestMeetingSpaceOrAttendees: attendeeCount ? attendeeCount.toString() : '',
    eventType: queryOption?.eventType || lastSearchedData.eventType,
    populateTodateFromFromDate: 'true',
    sgoSearch: 'false',
    sgoSupported: 'true',
    defaultToDateDays: '1',
    eventOptionsRadio: 'roomsOnly',
    formType: 'InCity',
    meetingSpaceUnits: '# of attendees',
    fromDate: getFormattedDateString(startDate, 'slashedDateWithMonthNoAndYear'),
    toDate: getFormattedDateString(endDate, 'slashedDateWithMonthNoAndYear'),
    monthNames: 'January,February,March,April,May,June,July,August,September,October,November,December',
    weekDays: 'S,M,T,W,T,F,S',
    brandCode: property?.basicInformation?.brand?.id,
    functionSpaceID: groupRate?.eventSpaceRates?.[0]?.id || '',
    groupId: searchQuery?.search?.groupId || '',
    marshaBrandCode: property?.basicInformation?.brand?.id,
    functionSpaceName: groupRate?.eventSpaceRates?.[0]?.name || '',
    eventSpaceSelection: getDatesInRange(startDate, endDate),
  };
  return bookNowPayload;
};

export const quickGroupDataStorage = new CacheManager<QuickGroupData>(QG_DATA_CACHE_KEY);

const updateQuickGroupData = (bookNowPayload: BookNowPayload) => {
  const quickGroupData: QuickGroupData = {
    groupId: bookNowPayload.groupId || '',
    functionSpaceId: bookNowPayload.functionSpaceID,
    functionSpaceName: bookNowPayload.functionSpaceName,
    propertyId: bookNowPayload.marshaCode,
    brandId: bookNowPayload.marshaBrandCode,
    startDate: bookNowPayload.fromDate,
    endDate: bookNowPayload.toDate,
    numberOfRooms: bookNowPayload.roomCount,
    numberOfAttendees: bookNowPayload.largestMeetingSpaceOrAttendees,
  };

  if (bookNowPayload.eventType !== OTHER_EVENT_TYPE) {
    quickGroupData.eventType = bookNowPayload.eventType;
  }

  quickGroupDataStorage.setItem(quickGroupData);
};

export const bookNow = async (url: string, bookNowPayload: BookNowPayload) => {
  const payload = new URLSearchParams();
  Object.entries(bookNowPayload).forEach(([key, value]) => {
    const newValue =
      key === 'eventSpaceSelection' && Array.isArray(value)
        ? JSON.stringify((value as { date: string }[]).map(item => ({ date: item.date })))
        : `${value}`;
    payload.append(key, newValue);
  });

  updateQuickGroupData(bookNowPayload);

  const response = await axios.post(url, payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      roomOnlySelectedAries: !!bookNowPayload.roomCount,
      roomAndEventSelectedAries: !!(bookNowPayload.roomCount && bookNowPayload.largestMeetingSpaceOrAttendees),
    },
  });
  return response;
};

export const getSearchType = (data: SearchQueryOptions) => {
  const { latitude, longitude, states, countries, destination } = data;
  const recentSearchItem = recentSearchesStorage.getLatestItem();

  const searchType =
    latitude && longitude
      ? SearchType.GEOLOCATION
      : states && recentSearchItem.states
      ? SearchType.LOCATION_STATE
      : countries
      ? SearchType.LOCATION_COUNTRY
      : destination
      ? SearchType.DESTINATION
      : SearchType.NONE;

  return searchType;
};
