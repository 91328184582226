import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, font, border, mediaQuery } = baseVariables;

const outline = `&:focus-visible {
  outline: -webkit-focus-ring-color auto ${toRem(1)}
}`;

export const StyledFilterModal = styled.div`
  .header-heading {
    font-size: ${font.fontL};
    line-height: ${font.lineHeightXl};
    font-weight: ${font.fontWeightMedium};
  }
  .modal-body {
    padding: ${toRem(39)} ${toRem(16)} ${toRem(15)} ${toRem(24)};
    label {
      font-weight: ${font.fontWeightRegular};
      width: fit-content;
    }
    .show-more-btn {
      ${outline}
    }
    @media only ${mediaQuery.md} {
      max-height: 55dvh !important;
    }
  }
  .footer-section {
    background-color: ${color.base20};
    border-radius: 0;
    .reset-cta {
      color: ${color.neutral00};
      background: transparent;
      font-weight: ${font.fontWeightMedium};
      &:hover {
        border-bottom: ${border.borderWidth02} ${border.borderStyleDefault} ${color.base10};
      }
      ${outline}
    }
  }
  .loading-view-container {
    overflow: hidden;
    .loading-view {
      opacity: 0.2;
      pointer-events: none;
    }
    .m-spinner-m {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0 ${toRem(8)} ${toRem(24)} 0;
    }
  }
  @media ${mediaQuery.md} {
    .header-heading {
      font-size: ${font.fontXxl};
      line-height: ${toRem(28)};
    }
    .footer-section {
      border-radius: 0 0 ${toRem(14)} ${toRem(14)};
    }
  }
`;
