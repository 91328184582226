import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import { PROPERTY_IMAGE_DIMENSIONS } from '../../constants';

const { mediaQuery, color, spacers, shadows } = baseVariables;

export const StyledPropertyCard = styled.div<{ showPricingInfo: boolean }>`
  box-shadow: ${shadows.boxShadowTile} 0 ${toRem(4)} ${toRem(20)} 0;
  border-radius: ${toRem(14)};
  background-color: ${color.base20};

  &.compact-card {
    display: block;
    height: auto;
    .property-info {
      padding: ${spacers.spacer} ${spacers.spacer} 0;
      &.without-footer {
        padding-bottom: ${spacers.spacer};
      }
      .view-details {
        margin: ${spacers.spacer} 0;
      }
    }
    .property-footer {
      margin: 0 ${toRem(-18)};
      justify-content: center;
    }
    @media ${mediaQuery.md} {
      max-width: ${toRem(PROPERTY_IMAGE_DIMENSIONS.REGULAR_MOBILE_IMAGE_WIDTH)};
    }
  }

  .property-media {
    position: relative;
    .carousel-image {
      border-radius: ${toRem(14)};
    }
    .brand-logo-container {
      position: absolute;
      bottom: ${spacers.spacer};
      left: ${spacers.spacer};
      .brand-logo-small {
        background: transparent;
        &::before {
          color: ${color.base20};
        }
      }
    }
    .media-badge {
      position: absolute;
      top: ${spacers.spacer};
      left: ${spacers.spacer};
      border-radius: ${spacers.spacers?.[1]};
      background-color: ${color.merch60};
      padding: ${toRem(6)} ${spacers.spacers?.[2]};
      text-transform: uppercase;
    }
  }

  .property-info {
    display: flex;
    flex-direction: column;
    padding: ${spacers.spacers?.[2]} ${spacers.spacer} 0;
    width: 100%;
    &.without-footer {
      padding-bottom: ${spacers.spacers?.[2]};
    }
    .property-name {
      h2 {
        cursor: pointer;
      }
    }
    .reviews-distance {
      color: ${color.neutral40};
      .rating-reviews {
        gap: ${spacers.spacers?.[1]};
      }
    }
    .view-details {
      margin: ${spacers.spacer} 0;
      padding: 0;
      background: transparent;
      align-self: flex-start;
      &:focus-visible {
        outline: -webkit-focus-ring-color auto ${toRem(1)};
      }
    }
  }

  .property-footer {
    border-top: ${toRem(1)} solid ${color.neutral20};
    margin: 0 ${toRem(-16)};
    padding: ${spacers.spacers?.[3]} ${spacers.spacer};
    justify-content: center;
    height: ${toRem(50)};
    .property-cta {
      min-width: ${toRem(128)};
    }
    .pricing-info {
      column-gap: ${toRem(16)};
      .label {
        line-height: ${toRem(12)};
        color: ${color.neutral40};
      }
      .price {
        line-height: ${toRem(24)};
      }
    }
    ${({ showPricingInfo }) => (showPricingInfo ? 'height: auto;' : '')}
  }

  @media ${mediaQuery.md} {
    display: flex;
    .property-info {
      padding: ${spacers.spacer} ${spacers.spacer} 0;
      &.without-footer {
        padding-bottom: ${spacers.spacer};
      }
      .view-details {
        margin: auto 0;
      }
    }
    .property-footer {
      justify-content: flex-end;
    }
  }
`;

export const StyledPropertyDescription = styled.div<{ columnCount: number }>`
  display: grid;
  grid-template-columns: ${props =>
    props.columnCount === 1
      ? '1fr 1fr'
      : `minmax(${toRem(156)}, min-content) auto minmax(${toRem(108)}, min-content) auto`};
  margin-top: ${spacers.spacers?.[4]};
  gap: ${props => (props.columnCount === 1 ? spacers.spacers?.[2] : spacers.spacers?.[1])};
  .attribute {
    grid-column: span 2;
  }

  @media ${mediaQuery.md} {
    grid-template-columns: ${props =>
      props.columnCount === 1
        ? '1fr 1fr'
        : `minmax(${toRem(147)},min-content) auto minmax(${toRem(80)}, min-content) auto`};
    margin-top: ${spacers.spacers?.[2]};
  }

  @media ${mediaQuery.lg} {
    margin-top: ${spacers.spacer};
    margin-bottom: ${spacers.spacers?.[3]};
  }
`;
