export type SubmitRfpInput = {
  propertyCode: string[];
  eventProfile: EventProfile;
  currency?: string;
  budgetAmount?: number;
  attachedDocs: AttachedDocs;
  eventDetails: EventDetails[];
  contactInfo: ContactInfo;
  additionalInformation: string;
  locale?: string;
};

type EventProfile = {
  name: string;
  startDate: string;
  endDate: string;
  isFlexibleDate: boolean;
  eventType: string;
  customEventType: string;
  numberOfAttendees: number;
  numberOfRooms: number;
};

type AttachedDocs = {
  key: string;
  name: string;
  description: string;
}[];

type EventDetails = {
  date: string;
  roomType?: RoomTypeInfo[];
  totalRoomCount: number;
  eventSpace?: EventSpace[];
};

type RoomTypeInfo = {
  type: RoomType;
  count: number;
};

export enum RoomType {
  GEN_STANDARD = 'GEN_STANDARD',
  GEN_STANDARD_DOUBLE = 'GEN_STANDARD_DOUBLE',
  GEN_SUITE = 'GEN_SUITE',
}

type EventSpace = {
  startTime: string;
  endTime: string;
  numberOfAttendees: number;
  isFoodBeverageRequired: boolean;
  isAudioVisualRequired: boolean;
  sittingArrangement: SittingArrangement;
};

export enum SittingArrangement {
  CO = 'CO', // Conference
  CR = 'CR', // Crescent Rounds
  EX = 'EX', // Exhibits
  HS = 'HS', // Hollow Square
  RL = 'RL', // Reception/Lounge
  RO = 'RO', // Rounds of 10
  SC = 'SC', // Schoolroom/Classroom
  TH = 'TH', // Theater
  US = 'US', // U-Shape
  NA = 'NA', // Special
}

type ContactInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phone: PhoneInfo;
  isJoinNow?: boolean;
  password?: string;
  confirmPassword?: string;
  isRememberMe?: boolean;
  isSubscription?: boolean;
  isThirdPartySubscription?: boolean;
  rewardId?: string;
  address: AddressInfo;
  gso?: GsoInfo;
};

type PhoneInfo = {
  dialingCountryCode: string;
  number: string;
};

type AddressInfo = {
  type: AddressType;
  company: {
    name: string;
  };
  line1: string;
  line2?: string;
  country: CountryInfo;
  city: string;
  state: StateInfo;
  zipCode: string;
};

export enum AddressType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'HOME',
  NONE = '',
}

export type CountryInfo = {
  code: string;
  name: string;
};

type StateInfo = {
  code?: string;
  name: string;
};

type GsoInfo = {
  name: string;
  email: string;
};

export type SubmitRfpResponse = {
  rfpId: string;
  rewardsId?: string;
  enrollmentStatus?: EnrollmentStatus | null;
};

export enum EnrollmentStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
