import { DEFAULT_LANG, LOCALE_MAP } from '../constants';
import { getCurrentLocale } from './date';

export function formatAmount(amount: number, fractionDigits = 2, isCurrencySymbol?: boolean): string {
  const locale = getCurrentLocale();
  const localeData = LOCALE_MAP[locale] || LOCALE_MAP[DEFAULT_LANG];
  const options = isCurrencySymbol ? { style: 'currency', currency: localeData['currency'] } : {};
  return new Intl.NumberFormat(LOCALE_MAP[locale] ? locale : DEFAULT_LANG, {
    ...options,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(amount);
}
