import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledMapView = styled.div`
  .map-marker {
    width: 80%;
    white-space: normal;
    line-height: ${toRem(18)};
    word-wrap: break-word;
    position: relative;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }

  .gmnoprint {
    div[data-control-height='81'] > div {
      border-radius: ${toRem(25)} !important;
      width: ${toRem(32)} !important;
      height: ${toRem(75)} !important;
      box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.6) !important;
    }
    div[data-control-height='81'] > div > div {
      height: 0 !important;
    }
  }

  .gm-control-active {
    &[title='Zoom in'] {
      border-radius: 50% !important;
      border: ${toRem(1)} solid ${color['neutral20']} !important;
      height: ${toRem(32)} !important;
      width: ${toRem(32)} !important;
      background: ${color['merch50']} !important;
    }
    &[title='Zoom out'] {
      border-radius: 50% !important;
      border: ${toRem(1)} solid ${color['neutral20']} !important;
      height: ${toRem(32)} !important;
      width: ${toRem(32)} !important;
      margin-top: ${toRem(10)} !important;
      background: ${color['merch50']} !important;
    }
    img {
      width: ${toRem(12)} !important;
    }
  }

  .gm-svpc {
    border-radius: 50% !important;
    height: ${toRem(32)} !important;
    width: ${toRem(32)} !important;
    box-shadow: 0 ${toRem(4)} ${toRem(32)} 0 rgba(0, 0, 0, 0.6);
    background-color: ${color['base20']} !important;
    img {
      height: ${toRem(24)} !important;
      box-shadow: 0 0 0 rgb(0 0 0 / 0.2);
    }
  }

  .gm-style-iw-c {
    border-radius: ${toRem(14)};
  }

  .gm-style-iw {
    padding: 0 !important;
    .gm-style-iw-d {
      overflow: hidden !important;
    }
  }

  .gm-svpc,
  .gm-control-active,
  .gm-style-cc button {
    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }
  }
`;
